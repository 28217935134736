(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var splide_1 = require("@splidejs/splide");
var banner = document.querySelector(".splide.photo");
var photos = new splide_1.Splide(banner, {
  type: 'fade',
  rewind: true,
  perPage: 1,
  arrows: false,
  pagination: false
});
var excerptSplide = new splide_1.Splide('.splide.summary', {
  perPage: 1,
  arrows: false,
  pagination: false,
  direction: 'ttb',
  height: '270px',
  rewind: true,
  // Avoid moving by mouse or touch.
  drag: false,
  swipeDistance: 0,
  breakpoints: {
    768: {
      height: '380px'
    },
    400: {
      height: '420px'
    }
  }
});
if (document.querySelector('.splide.previews')) {
  var previewsSplide = new splide_1.Splide('.splide.previews', {
    perPage: 5,
    perMove: 1,
    rewind: true,
    pagination: true,
    breakpoints: {
      1400: {
        perPage: 5
      },
      1200: {
        perPage: 3.2
      },
      992: {
        perPage: 2.4
      },
      768: {
        perPage: 1.6,
        focus: 'center'
      },
      400: {
        perPage: 1.2,
        focus: 'center'
      }
    }
  });
  excerptSplide.mount();
  photos.sync(excerptSplide).mount();
  previewsSplide.sync(excerptSplide).sync(photos).mount();
  var previewsButtonsWrapper = document.querySelector('.splide.previews .splide__list');
  previewsButtonsWrapper.addEventListener('click', function (e) {
    if (e.target instanceof HTMLImageElement) {
      var button = e.target.closest('button.preview');
      var index = parseInt(button.getAttribute('data-index'));
      excerptSplide.go(index);
    }
  });
} else {
  excerptSplide.mount();
  photos.mount();
}

},{"@splidejs/splide":"@splidejs/splide"}]},{},[1]);
